@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  @apply bg-secondary
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
/* Hide the checkbox input */
input[type="checkbox"].hidden {
  display: none;
}

/* Collapsible content default state */

div.overflow-hidden {
  overflow: hidden;
  max-height: 0;
  transition: max-height 0.5s ease-in-out;
  
}

/* Expand content when the associated checkbox is checked */
input[type="checkbox"]:checked + label + div {
  max-height: 1000px; /* Arbitrary large value to fit the content */
  
}
